// extracted by mini-css-extract-plugin
export var formCaptcha = "contact-form-module--formCaptcha--0RMPi";
export var formContainer = "contact-form-module--formContainer--LjVsM";
export var formEmail = "contact-form-module--formEmail--CTJuN";
export var formMessage = "contact-form-module--formMessage--B6jVk";
export var formMotive = "contact-form-module--formMotive--dVgFB";
export var formName = "contact-form-module--formName--emilJ";
export var formPhone = "contact-form-module--formPhone--bemc5";
export var formRow = "contact-form-module--formRow--1K0bn";
export var formRowContainer = "contact-form-module--formRowContainer--gf0jz";
export var messageContainer = "contact-form-module--messageContainer--rdQMh";
export var submitBtn = "contact-form-module--submitBtn--reVY7";