import * as React from "react";
import {useState} from 'react';
import { useForm } from "react-hook-form";
import * as styles from './contact-form.module.css';
import * as basestyles from './../layout.module.css';
import HCaptcha from "@hcaptcha/react-hcaptcha";
import Spinner from "./spinner";


const formAction = `${process.env.GATSBY_FORM_ACTION}`;
const captchaSitekey = `${process.env.GATSBY_CAPTCHA_SITE_KEY}`;

const sendForm = async (data, captchaResponse) => {
  const requestBody = {...data, 'h-captcha-response': captchaResponse};

  let response = await fetch(formAction, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Accept: "application/json",
    },
    body: JSON.stringify(requestBody)
  });

  if (response.status >= 400 && response.status < 500) {
    throw Error("Client error");
  }

  if (response.status >= 500 && response.status < 600) {
    throw Error("Server error");
  }

  let result = await response.json();

  return result;
};

const ContactForm = () => {
  const { register, handleSubmit, formState: { isSubmitted, isSubmitting, isSubmitSuccessful } } = useForm();
  const [token, setToken] = useState(null);
  const [formSendError, setFormSendError] = useState(false);

  const onFormSubmit = async (data) => {
    if (token) {
        try {
          const formResult = await sendForm(data, token);
        } catch(err) {
          setFormSendError(true);
        }
    }
  };

  const onCaptchaExpire = () => {
    setToken(null);
    console.log("hCaptcha Token Expired");
  };

  const onCaptchaError = (err) => {
    console.log(`hCaptcha Error: ${err}`);
  };

  return (
    <div className={styles.formContainer}>
      {isSubmitted && formSendError && <div className={styles.messageContainer}><h3>Hubo un error enviando tu mensaje.</h3><h3>Por favor intenta más tarde.</h3></div>}
      {!formSendError && isSubmitSuccessful && <div className={styles.messageContainer}><h3>¡Tu mensaje fue enviado!</h3></div>}
      {!isSubmitted && <form onSubmit={handleSubmit(onFormSubmit)}>
          <div className={styles.formRowContainer}>
              <div className={[styles.formRow, styles.formName].join(' ')}>
                  <label htmlFor="name" className={basestyles.visuallyHidden}>Nombre:</label>
                  <input id="name" type="text" name="name" placeholder="Nombre:" required {...register('name')}/>
              </div>
    
              <div className={[styles.formRow, styles.formEmail].join(' ')}>
                  <label htmlFor="email" className={basestyles.visuallyHidden}>Correo:</label>
                  <input id="email" type="email" name="email" placeholder="Correo:" required {...register('email')} />
              </div>
    
              <div className={[styles.formRow, styles.formPhone].join(' ')}>
                  <label htmlFor="phone" className={basestyles.visuallyHidden}>Teléfono:</label>
                  <input id="phone" type="tel" name="phone" placeholder="Teléfono:" required {...register('phone')}/>
              </div>
    
              <div className={[styles.formRow, styles.formMotive].join(' ')}>
                  <label htmlFor="motive" className={basestyles.visuallyHidden}>Motivo:</label>
                  <select id="motive" name="motive" required defaultValue="Motivo:" {...register('motive')}>
                    <option value="Motivo:" disabled hidden>Motivo:</option>
                    <option value="Inscripción individual">Inscripción individual</option>
                    <option value="Patrocinador">Patrocinador</option>
                    <option value="Información general">Información general</option>
                  </select>
              </div>
    
              <div className={[styles.formRow, styles.formMessage].join(' ')}>
                  <label htmlFor="message" className={basestyles.visuallyHidden}>Mensaje:</label>
                  <textarea id="message" name="message" placeholder="Mensaje:" required {...register('message')}/>
              </div>
    
              <div className={styles.formCaptcha}>
                <HCaptcha
                  sitekey={captchaSitekey}
                  languageOverride="es"
                  onVerify={setToken}
                  onError={onCaptchaError}
                  onExpire={onCaptchaExpire}
                />
              </div>
    
              <div className={styles.formRow}>
                  <button className={[styles.submitBtn, basestyles.animate].join(' ')} type="submit" disabled={!token || isSubmitting}>
                    {isSubmitting && <Spinner />}
                    Enviar
                  </button>
              </div>
          </div>
      </form>
      }
    </div>
  );
}

export default ContactForm;