import * as React from "react";
import * as styles from './spinner.module.css';

const Spinner = () => {  

  return (
    
    <div className={styles.ldsSpinner}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    
  );
}

export default Spinner;