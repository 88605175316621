import * as React from "react";
import Layout from "../components/layout";
import Contacto from "../components/pagecomponents/contacto";
import PageComponentLayout from "../components/pagecomponents/pagecomponentlayout";
import Seo from "../components/utils/seo";

const ContactoPage = () => {
  return (
    <Layout>
      <PageComponentLayout title="Contacto">
        <Contacto />
      </PageComponentLayout>
    </Layout>
  );
};

export default ContactoPage;

export const Head = () => <Seo title="Contacto" />
