import * as React from "react";
import ContactForm from "../utils/contact-form";
import * as styles from './contacto.module.css';

const Contacto = () => {
  return (
    <div className={styles.contactoContainer}>
      <ContactForm />
    </div>
  );
};

export default Contacto;
